.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.App-header {
    background-color: #79d1c5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.expired-notice {
    text-align: center;
    /* padding: 2rem; */
    /* border: 1px solid #ebebeb; */
    /* border-radius: 0.25rem; */
    /* margin: 0.5rem; */
}

.expired-notice > span {
    font-size: 2rem;
    font-weight: bold;
    color: red;
}

.expired-notice > p {
    font-size: 1.5rem;
}

.show-counter {
    padding: 0.5rem;
}

.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-family: 'VarinonormalRegular';
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #1877f2;
    /* background: black; */
}
.show-counter .countdown-link-dark-mode {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-family: 'VarinonormalRegular';
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #74ec67;
    /* change color here */
    /* color: #00acac; */
}

.show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    letter-spacing: 1px !important;
}

.show-counter .countdown.danger {
    color: #ff0000;
}

.show-counter .countdown > p {
    margin: 0;
    font-size: 2rem;
    margin-bottom: 1rem !important;
    font-family: unset;
}

.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
}
#minitimer .show-counter {
    padding: 0rem;
}
#minitimer .show-counter .countdown {
    padding: 0rem 0.2rem 0rem 0.2rem;
}

#minitimer .show-counter .countdown-link,
#minitimer .show-counter .countdown-link-dark-mode {
    padding: 0.5rem 0rem 0rem 0rem !important;
}
#minitimer .show-counter .countdown-link > p,
#minitimer .show-counter .countdown-link-dark-mode > p {
    margin: 0;
}

#minitimer .show-counter .countdown > p {
    margin: 0;
    font-size: 1rem;
    margin-bottom: 0rem !important;
    font-family: unset;
}

#minitimer .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.5rem;
    line-height: 1rem;
}
#minitimer div.expired-notice > span {
    font-size: 1rem;
}

.mdb-dataTables_paginate {
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
}
