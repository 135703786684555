body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    background:#09080a;
    height: 100%;
}
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
/* @font-face {
    font-family: 'VarinonormalRegular';
    src: local('VarinonormalRegular'), url('./assets/fonts/PlayfairDisplaySC-Regular.otf') format('truetype');
} */

@font-face {
  font-family: 'frozen';
  src: local('frozen'), url('./assets/fonts/frozen.ttf') format('truetype');
}

@font-face {
  font-family: 'bicubik';
  src: local('bicubik'), url('./assets/fonts/bicubik/bicubik.otf') format('truetype');
}

@font-face {
  font-family: 'AmazOOSTROBBold';
  src: local('AmazOOSTROBBold'), url('./assets/fonts/AmazObitaemOstrov/AmazOOSTROBBold.ttf') format('truetype');
}

@font-face {
  font-family: 'AmazOOSTROVFine';
  src: local('AmazOOSTROVFine'), url('./assets/fonts/AmazObitaemOstrov/AmazOOSTROVFine.ttf') format('truetype');
}

@font-face {
  font-family: 'AmazOOSTROVv.2';
  src: local('AmazOOSTROVv.2'), url('./assets/fonts/AmazObitaemOstrov/AmazOOSTROVv.2.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

h2{
  font-family: 'bicubik';
  letter-spacing: 0.1em;
  /* font-family: roboto-regular; */
}
p {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
  font-family: 'Montserrat-Regular', sans-serif;
}
td {
  font-family: 'Montserrat-Regular', sans-serif;
}
th{
  font-family: 'bicubik';
	letter-spacing: 0.1em;
  /* font-size: 0.9rem !important; */
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #040609;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #00DFFF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #00DFFF;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
iframe {
    position: relative !important;
}
.Toastify__toast--error {
    background-color: #e74c3d !important;
    color: white !important;
}
.Toastify__toast--success {
    background-color: #0abc0a !important;
    color: white !important;
}

.customOverlay {
	background: #b8b8b821 !important;
  }

.customModal{
	background: #191a1c !important;
  border-radius: 1.2rem;
}
@media screen and (min-width: 600px) and (max-width: 9999px) {
  .customModal{
    width:500px;
  }
}

.closeModel{
	background-color: #00DFFF !important;
}


.howConnectMetaMaskOverLay {
	background: #0b0000c4 !important;
  }

.howConnectMetaMaskModal{
    background: #191a1c !important;
    border-radius: 1.2rem;
  }

@media screen and (min-width: 767px) and (max-width: 9999px) {
    .howConnectMetaMaskModal{
      width:800px;
    }
  }


.react-datetime-picker__calendar--open .react-calendar,
.react-datetime-picker__clock--open {
    border: 0.1rem solid #d4d4d4 !important;
    border-radius: 0.5rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 20%), 0 4px 8px rgb(0 0 0 / 6%), 0 8px 12px rgb(0 0 0 / 12%), 0 12px 16px rgb(0 0 0 / 18%);
}
.react-calendar__month-view__weekdays__weekday {
    color: #1877f2 !important;
}
.react-calendar__month-view__days__day,
.react-calendar__navigation__label__labelText {
    font-weight: bold;
}
.Toastify__toast-container {
    width: 32rem !important;
}

div.mdb-datatable div.mdb-datatable-length label {
    color: #8c9b94;
}
div.mdb-datatable div.mdb-datatable-info {
    color: #8c9b94;
}

#piechart > div > div > div {
  display:flex;
  justify-content: center;
}


.animated-button-dark {
  /* background: linear-gradient(-30deg, #0b3d3d 50%, #082b2b 50%); */
  background: linear-gradient(159.04deg,rgba(0,223,255,0.5) 13.85%,rgba(0,121,209,0.5) 95.84%);
  padding: 0.9rem 1.23rem;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  /* color: #d4f7f7; */
  color: white;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2.5px;
  text-align: center;
  /* text-transform: uppercase; */
  text-decoration: none;
  /* -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); */
}

.animated-button-dark::before {
  content: '';
  position: absolute;
  color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(159.04deg,rgba(0,223,255,0.5) 13.85%,rgba(0,121,209,0.5) 95.84%);
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button-dark:hover::before {
  opacity: 0.2;

}

.animated-button-dark:hover {
    color: white;
    background: linear-gradient(159.04deg,rgba(0,223,255,0.5) 13.85%,rgba(0,121,209,0.5) 95.84%);
  }

.animated-button-dark span {
  position: absolute;
}

.animated-button-dark span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 43, 0)), to(#00DFFF));
  background: linear-gradient(to left, rgba(8, 43, 43, 0), #00DFFF);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button-dark span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 43, 0)), to(#00DFFF));
  background: linear-gradient(to top, rgba(8, 43, 43, 0), #00DFFF);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button-dark span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 43, 0)), to(#00DFFF));
  background: linear-gradient(to right, rgba(8, 43, 43, 0), #00DFFF);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button-dark span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 43, 0)), to(#00DFFF));
  background: linear-gradient(to bottom, rgba(8, 43, 43, 0), #00DFFF);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button-light {
    /* background: linear-gradient(-30deg, #0b3d3d 50%, #082b2b 50%); */
    background: #e7f3ff;
    padding: 0.9rem 1.23rem;
    display: inline-block;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    overflow: hidden;
    /* color: #d4f7f7; */
    color: #2b87be;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2.5px;
    text-align: center;
    /* text-transform: uppercase; */
    text-decoration: none;
    /* -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
            box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); */
  }

  .animated-button-light::before {
    content: '';
    position: absolute;
    color: white;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #082b2b;
    opacity: 0;
    -webkit-transition: .2s opacity ease-in-out;
    transition: .2s opacity ease-in-out;
  }

  .animated-button-light:hover::before {
    opacity: 0.1;

  }

  .animated-button-light:hover {
      color: #2b87be;
      background: #d2e9ff;
    }

  .animated-button-light span {
    position: absolute;
  }

  .animated-button-light span:nth-child(1) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 43, 0)), to(#2b87be));
    background: linear-gradient(to left, rgba(8, 43, 43, 0), #2b87be);
    -webkit-animation: 2s animateTop linear infinite;
            animation: 2s animateTop linear infinite;
  }

  @keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }

  .animated-button-light span:nth-child(2) {
    top: 0px;
    right: 0px;
    height: 100%;
    width: 2px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 43, 0)), to(#2b87be));
    background: linear-gradient(to top, rgba(8, 43, 43, 0), #2b87be);
    -webkit-animation: 2s animateRight linear -1s infinite;
            animation: 2s animateRight linear -1s infinite;
  }

  @keyframes animateRight {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  }

  .animated-button-light span:nth-child(3) {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 43, 0)), to(#2b87be));
    background: linear-gradient(to right, rgba(8, 43, 43, 0), #2b87be);
    -webkit-animation: 2s animateBottom linear infinite;
            animation: 2s animateBottom linear infinite;
  }

  @keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }

  .animated-button-light span:nth-child(4) {
    top: 0px;
    left: 0px;
    height: 100%;
    width: 2px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 43, 0)), to(#2b87be));
    background: linear-gradient(to bottom, rgba(8, 43, 43, 0), #2b87be);
    -webkit-animation: 2s animateLeft linear -1s infinite;
            animation: 2s animateLeft linear -1s infinite;
  }

  @keyframes animateLeft {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
    }
  }

  /* .table-bordered, .mdb-datatable table tr{
    border: 1px solid red;
    border-radius: 2rem;
  } */


/* .mdb-datatable table tr {
    border-bottom: 1px solid red;
}

table.table thead th {
    border: 1px solid red;
}
table.table.btn-table td {
  border: 1px solid red;
} */

/* .mdb-datatable-table{
  border: 1px solid red;
} */


.table{
  margin-bottom: 0rem;
}

div[data-test="mdb-datatable-table"] {
        border: 1px solid #00b1cc;
    border-radius: 0.5rem;
    padding:0;
    overflow: hidden;
}

thead tr{
  background: linear-gradient(90deg, rgb(0, 178, 204) 5.72%, rgba(0, 177, 204, 0.604) 95.59%) !important;
  color: rgb(255, 255, 255) !important;
}

.mdb-datatable table tbody > :last-child {
  border:none;
}

.mdb-datatable table tr{
  border-bottom: 1px solid #00b1cc;
}

.mdb-datatable table tbody > :not(:last-child) {
    border-bottom: 1px solid #00b1cc;
}

.mdb-datatable table tbody > tr td , .mdb-datatable table thead > tr th {
  border-right: 1px solid #00b1cc;
}

.mdb-datatable table tbody > tr td {
  font-size: 14px;
  color:#fafafa;
}


table.mdb-dataTable tbody > tr:not(.selected):hover {
  /* background: linear-gradient(91.88deg,#00DFFF 5.72%,#0085FF 95.59%); */
  background: linear-gradient(90deg, rgba(0, 223, 255, 0.25) 50.72%, rgba(0, 223, 255, 0.25) 95.59%) !important;
  font-weight: bold !important;
}
tr td{
  font-weight: bold !important;
}

/* react-google-charts tooltip flicker */
svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}


.loading_logo_animation{
	animation: mymove 1.2s infinite;
	animation-timing-function: linear;
  }

  @keyframes mymove {
    0% {transform:scale(1.3);}
    50% {transform:scale(1);}
    100% {transform:scale(1.3);}
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none; /* Webkit browsers */
      margin: 0; /* Removes the inner spin button */
      }


/*-------- Shimmer Text animation Start ---------*/
      .shimmer {
        /* font-weight: 500; */
        font-size: 0.75rem;
        margin: 0 auto;
        /* padding: 0 140px 0 0; */
        display: inline;
        margin-bottom: 0;
    }
    
    .shimmer {
        text-align: center;
        color: rgba(255, 255, 255, 0.1);
        background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, rgb(113, 113, 113)));
        background: -moz-gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, rgb(113, 113, 113)));
        background: gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, rgb(113, 113, 113)));
        -webkit-background-size: 125px 100%;
        -moz-background-size: 125px 100%;
        background-size: 125px 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-animation-name: shimmer;
        -moz-animation-name: shimmer;
        animation-name: shimmer;
        -webkit-animation-duration: 3.5s;
        -moz-animation-duration: 3.5s;
        animation-duration: 3.5s;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        background-repeat: no-repeat;
        background-position: -300px 0;
        background-color: #fff;
    }
    
    @-moz-keyframes shimmer {
      0% {
          background-position: -300px top;
      }
      100% {
          background-position: 300px top;
      }
  }
  
  @-webkit-keyframes shimmer {
      0% {
          background-position: -300px top;
      }
      100% {
          background-position: 300px top;
      }
  }
  
  @-o-keyframes shimmer {
      0% {
          background-position: -300px top;
      }
      100% {
          background-position: 300px top;
      }
  }
  
  @keyframes shimmer {
      0% {
          background-position: -300px top;
      }
      100% {
          background-position: 300px top;
      }
  }

/*-------- Shimmer Text animation End ---------*/

.froz {
  transform: translate(0, -40%);
  /* transform: translate(0, 0%); */
  font-size: 1.8rem;
  margin: 0;
  font-family: "frozen", serif;
  font-weight: 700;
  background-image: url("https://assets.codepen.io/209981/ice.jpg");
  -webkit-background-clip: text;
  background-size: contain;
  color: transparent;
  font-weight: 900;
  position: relative;
}
.froz::after {
  content: attr(data-heading);
  position: absolute;
  left: 0;
  /* text-shadow: 1px -1px 2px rgba(255, 255, 255, 0.2), 1px -1px 2px rgba(255, 255, 255, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.2), 2px 2px 2px rgba(41, 131, 172, 0.2), -2px 2px 2px rgba(41, 131, 172, 0.2), -2px -2px 2px rgba(41, 131, 172, 0.2), 3px 3px 30px rgba(125, 204, 239, 0.5), -3px 3px 30px rgba(125, 204, 239, 0.5), -3px -3px 30px rgba(125, 204, 239, 0.5), -6px 6px 30px rgba(58, 122, 155, 0.5), 6px 6px 30px rgba(58, 122, 155, 0.5), -6px -6px 30px rgba(58, 122, 155, 0.5); */
  color: transparent;
  mix-blend-mode: color-burn;
  background-image: url("https://assets.codepen.io/209981/ice.jpg");
  -webkit-background-clip: text;
  background-size: contain;
}

.froz::before {
  content: attr(data-heading);
  position: absolute;
  left: 0;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, 0) 100%);
  -webkit-background-clip: text;
  color: transparent;
  animation: shine 5s infinite;
  background-size: 200%;
  /* -webkit-text-stroke: 1px #4f90ab; */
}

@keyframes shine {
  0% {
    background-position: -120%;
 }
  10% {
    background-position: 120%;
 }
  100% {
    background-position: 120%;
 }
}

/* ................................ */


.card_shine {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.card_shine::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 10%;
  z-index: 2;
  opacity: 0.5;
}
.card_shine:hover::before, .card_shine:focus::before {
  animation: card_shine 1s;
}
@keyframes card_shine {
  100% {
    left: 125%;
 }
}

/* ----------------------------------- */
.cardWrapper span {
  transition: 0.5;
  opacity: 0;
}

.cardWrapper:hover span {
  opacity: 1;
}

.cardWrapper span:nth-child(1) {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 3px;
  /* background: linear-gradient(to right, transparent, #4caf50 ); */
  animation: animate1 2s linear infinite;
      /* animation-delay: 1s; */
}

@keyframes animate1 {
  0%{
      transform: translateX(-100%);
  }
  100%{
      transform: translateX(100%);
  }
}

.cardWrapper span:nth-child(2) {
  position: absolute;
  top:0;
  right:0;
  width: 3px;
  height: 100%;
  /* background: linear-gradient(to bottom, transparent,  #4caf50 ); */
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate2 {
0%{
  transform: translateY(-100%);
}
100%{
  transform: translateY(100%);
}
}

.cardWrapper span:nth-child(3) {
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
  height: 3px;
  /* background: linear-gradient(to left, transparent,  #4caf50 ); */
  animation: animate3 2s linear infinite;
  /* animation-delay: 1s; */
}

@keyframes animate3 {
0%{
  transform: translateX(100%);
}
100%{
  transform: translateX(-100%);
}
}

.cardWrapper span:nth-child(4) {
  position: absolute;
  top:0;
  left:0;
  width: 3px;
  height: 100%;
  /* background: linear-gradient(to top, transparent, #4caf50 ); */
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate4 {
0%{
  transform: translateY(100%);
}
100%{
  transform: translateY(-100%);
}
}


